<template>
  <div class="main">
    <div class="container">
      <h1 id="title" class="text-center">Conócenos</h1>

      <div class="div_p">
        <p class="p_1">
          Con CBI Agro-Tech ®️ invierte en el sector agropecuario, por medio de
          estructuras financieras sólidas y seguras que se suman la producción
          de recursos naturales de alta demanda en México y el extranjero que
          permiten estimar rendimientos mayores al 30% promedio anual.
        </p>
        <p class="p_2">
          El capital se invierte en proyectos específicos como producción de
          hortalizas, huevo, cárnicos o plantaciones.
        </p>
        <p class="p_3">
          La finalidad de esta unidad es generar ganancias
          competitivas que logren multiplicar el capital de nuestros
          accionistas, protegerlo ante los cambios en la economía del mundo y
          también fomentar el crecimiento del sector agropecuario en el Estado
          de Oaxaca.
        </p>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h1 class="text-center">Oportunidad</h1>
            <h4 class="text-center">INVERSIÓN RECREATIVA</h4>
            <br />
            <p>
              Conviértase en el dueño de una plantación de tuberculos en Oaxaca,
              estado originario del mezcal y sus derivados. Visite el rancho y
              conozca por medio de un extraordinario tour recreativo y temático
              el crecimiento de su inversión.
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h1 class="text-center">¿Por qué comprar?</h1>
            <h4 class="text-center">ALTA DEMANDA DE SNACKS</h4>
            <br />
            <p>
              <!-- La tradición, fiesta mexicana y ferias regionales en todo el país
              demandan el consumo de las bebidas como el mezcal, el tequila y la
              miel de agave, por lo que la producción de maguey ya no es
              suficiente, convirtiéndose en una gran oportunidad de negocio. -->
            </p>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <h1>Inversión mínima</h1>
            <h4>APORTACIÓN EFECTIVA</h4>
            <p>
              El monto mínimo para la inversión efectiva en plantación de maguey
              es de: <strong><h2>$45,000.00 mxn</h2></strong>
            </p>
          </v-col> -->
        </v-row>
        <h2 class="text-center" style="margin-bottom: 50px">
          CONOZCA NUESTRO PROYECTO DE INVERSIÓN EN PLANTACIÓN DE TUBERCULOS
        </h2>
        <!-- <div class="div_images">
          <div class="div_image" v-for="(image, index) in 14" :key="index">
            <v-img :src="getSrc(index + 1)"></v-img>
          </div>
        </div> -->
        <h1 class="text-center h1_comienza">COMIENZA AQUÍ FÁCILMENTE</h1>
        <br /><br>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <h2 class="text-center">PERFILAMIENTO</h2>
            <h3 class="text-center">QUEREMOS CONOCERLE</h3>
            <br />
            <p class="p_last_1">
              Partimos del llenado de una solicitud para contactar con usted y
              exponerle grandes beneficios de invertir en ROOTSTOKEN, así como
              programar una visita a la plantación para que conozca el proyecto
              usted mismo.
            </p>
            <div
              class="div-btn"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                class="btn_last"
                to="/diagnostico_de_inversionista"
                color="#85af61"
                dark
                >Iniciar solicitud</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <h2 class="text-center">ACOMPAÑAMIENTO</h2>
            <h3 class="text-center">ESTAMOS CON USTED</h3>
            <br />
            <p class="p_last_2">
              Para la tranquilidad y confianza de nuestros clientes, le
              acompañamos paso a paso en la toma de decisiones para realizar la
              mejor inversión. Comuníquese con nosotros vía whatsapp y resuelva
              todas sus dudas.
            </p>
            <div
              class="div-btn"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                class="btn_last"
                href="https://api.whatsapp.com/send?phone=525564931947&text=Hola,%20¿Me%20puedes%20brindar%20más%20información%20sobre%20ROOTSTOKEN?%20Visite%20la%20página%20http://rootstoken.io/"
                target="_blank"
                color="#85af61"
                dark
                >Enviar whatsapp</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <h2 class="text-center">MONITOREO</h2>
            <h3 class="text-center">TENGA EL CONTROL</h3>
            <br />
            <p class="p_last_3">
              Uno de los valores agregados de CBI Agro-Tech es el seguimiento
              puntual de la inversión de nuestros clientes, por lo que
              trimestralmente recibirá un estado de cuenta con los rendimientos
              de tu inversión.
            </p>
            <div
              class="div-btn"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                class="btn_last"
                href="https://api.whatsapp.com/send?phone=525564931947&text=Hola,%20¿Me%20puedes%20brindar%20más%20información%20sobre%20ROOTSTOKEN?%20Visite%20la%20página%20http://rootstoken.io/"
                target="_blank"
                color="#85af61"
                dark
                >Comenzar aquí</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    getSrc(image) {
      return require("../assets/pr" + image + ".jpg");
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 1904px) {
  .main {
    width: 100%;
    /* height: 100vh; */
    background-color: white;
    margin-bottom: 400px;
  }
  .div_p {
    margin-top: 0%;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #85af61;
  }
  #title {
    margin-top: 8%;
    margin-bottom: 1%;
  }
  p {
    /* height: 100px; */
  }
  .div_images {
    margin-bottom: 10%;
  }
  .row {
    margin-bottom: 5%;
  }
  .p_last_1,
  .p_last_2,
  .p_last_3 {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) {
  .main {
    width: 100%;
    /* height: 100vh; */
    background-color: white;
    margin-bottom: 400px;
  }
  .div_p {
    margin-top: 0%;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #85af61;
  }
  #title {
    margin-top: 10%;
    margin-bottom: 1%;
  }
  p {
    font-size: 14px;
  }
  .div_images {
    margin-bottom: 10%;
  }
  .row {
    margin-bottom: 5%;
  }
  .p_last_1,
  .p_last_2,
  .p_last_3 {
    padding: 30px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) {
  .main {
    width: 100%;
    /* height: 100vh; */
    background-color: white;
    margin-bottom: 400px;
  }
  .div_p {
    margin-top: 0%;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #85af61;
  }
  #title {
    margin-top: 15%;
    margin-bottom: 1%;
  }
  p {
    /* height: 100px; */
    font-size: 12px;
  }
  .div_images {
    margin-bottom: 10%;
  }
  .row {
    margin-bottom: 5%;
  }
  .h1_comienza {
    text-align: center;
    margin-bottom: 70px;
  }
  .p_1,
  .p_2,
  .p_3 {
    font-size: 1em;
  }
  .p_last_1,
  .p_last_2,
  .p_last_3 {
    padding-right: 50px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .main {
    width: 100%;
    /* height: 100vh; */
    background-color: white;
    margin-bottom: 400px;
  }
  .div_p {
    margin-top: 0%;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #85af61;
  }
  #title {
    margin-top: 100px;
    margin-bottom: 1%;
  }
  p {
    font-size: 12px;
  }
  .div_images {
    margin-bottom: 10%;
  }
  .row {
    margin-bottom: 5%;
  }
  .h1_comienza {
    text-align: center;
    margin-bottom: 70px;
  }
  .p_1,
  .p_2,
  .p_3 {
    font-size: 1em;
  }
  .p_last_1,
  .p_last_2,
  .p_last_3 {
    margin-bottom: -30px;
    margin-top: 20px;
    padding-right: 100px;
    padding-left: 100px;
    height: 100px;
  }
}
@media screen and (max-width: 600px) {
  .main {
    width: 100%;
    /* height: 100vh; */
    background-color: white;
    margin-bottom: 400px;
  }
  .div_p {
    margin-top: 0%;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #85af61;
  }
  #title {
    margin-top: 100px;
    margin-bottom: 1%;
  }
  p {
    /* height: 100px; */
    font-size: 12px;
  }
  .div_images {
    margin-bottom: 10%;
  }
  .row {
    margin-bottom: 5%;
  }
  .h1_comienza {
    text-align: center;
    margin-bottom: 70px;
  }
  .p_1,
  .p_2,
  .p_3 {
    font-size: 0.8em;
  }
  .p_last_1,
  .p_last_2,
  .p_last_3 {
    /* margin-bottom: -30px; */
    margin-top: 10px;
    padding-right: 70px;
    padding-left: 70px;
    margin-bottom: 40px;
  }
}
/* .container{
  height: 600px;
} */
</style>