<template>
  <div class="main">
    <Conocenos />
  </div>
</template>
<script>
import Conocenos from "@/components/ConocenosC.vue";
export default {
  components: {
    Conocenos,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>